export const SET_FORM = 'SET_FORM'


export interface Form {
  type: string
  value: number
  isNew: string
  terms: boolean
  cpf: string
  name: string
  uf: string
  email: string
  confirmEmail: string
  city: string
  birthDate:string 
  phone: string
  income: number
  changeEmail: boolean
  familyIncome: string
  has36OrMoreFGTSContributions: string
  hasAccountOrFGTSHigherThanTenPercentValue: string
  livesOrWorkOnRealStateCity: string
  ownsOnSameCityOrHasActiveSFH: string
  personType: string
  firstStepId?: string
  secondStepId?: string
  thirdStepId?: string
  rg?: string
  rne?: string
  motherName: string
}

export interface IForm {
  type: string
  value: number
  isNew: string
  terms?: boolean
  cpf?: string
  name?: string
  uf?: string
  email?: string
  birthDate?:string
  city?: string
  phone?: string
  income?: number
  familyIncome?: string
  changeEmail?: boolean
  has36OrMoreFGTSContributions?: string
  hasAccountOrFGTSHigherThanTenPercentValue?: string
  livesOrWorkOnRealStateCity?: string
  ownsOnSameCityOrHasActiveSFH?: string
  firstStepId?: string
  personType?: string  
  secondStepId?: string
  thirdStepId?: string
  confirmEmail?: string
  rg?: string
  rne?: string
  motherName?: string
}

interface setForm {
  type: typeof SET_FORM
  payload: Form
}

interface setPersonType {  
  type: 'SET_FORM_PERSON_TYPE'
  payload: {
    personType: string    
  }
}

interface setFormItem {
  type: 'SET_FORM_ITEM',
  payload: {
    key: string,
    value: any
  }
  
}
export type FormTypes = setForm | setPersonType | setFormItem