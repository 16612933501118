
import React  from 'react';
import {  Text } from "@chakra-ui/react"

import { connect } from 'react-redux'

const mapState = () => {
  return {}
}

const mapDispatch = () => ({})

const connector = connect(mapState, mapDispatch)


const ErrorPage = () => {

  return (
    <>
      <Text color="var(--secondary-color-600)" fontWeight="600" fontSize="2.5rem">Não é possível prosseguir!</Text>
      <Text color="var(--secondary-color-600)" fontWeight="400" fontSize="1.25rem">É necessário uma indicação para prosseguir.</Text>
    </>
  );
};


export default connector(ErrorPage);