import React, { ReactElement, useState, useEffect } from "react"
import { TextInput, Radio, Select, DatePicker } from "../../components/FormInputs"
import { Box, Button, Grid, GridItem, Text, useBreakpointValue } from "@chakra-ui/react"
import { Option } from "../../types"
import {
  getStateCities,
  getStates,
} from "../../utils/brazilStatesAndCities"
import { RootState } from "../../store/modules/rootReducer"
import { connect, ConnectedProps, useDispatch } from "react-redux"
import format from "../../utils/format"
import { layout } from '../../config/projectStyle'
import mainApi from '../../services/mainApi'
import { setImovelValues } from "../../store/modules/global/actions"
import InputMoney from "../../components/InputMoney/InputMoney"
import ModalIncome from "../../components/Modals/ModalIncome"
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined'
import theme from "../../assets/styles/theme"
import { useHistory } from "react-router-dom"
import queryString from 'query-string'
import ModalConfirmEmail from "components/Modals/ModalConfirmEmail"
import { useSimulator } from "hooks"
import interate from "utils/interate"
import { setError } from "store/modules/error/actions"
import Alert from 'components/Alert/Alert'
import { setFormItem } from "store/modules/form/actions"
import ModalTerms from "components/Modals/ModalTerms"
import LGPDText from "components/TermsLGPD/LGPDText"
import Check from "components/FormInputs/Checkbox"
import { _followUp } from "store/modules/global/types"

const mapState = (state: RootState) => {
  return {
    form: state.form,
    step: state.step.active,
    errors: state.error,
    global: state.global
  }
}

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux {
  setStep: any
}

const connector = connect(mapState)

const StepOne = ({ form, errors, global, setStep }: IProps): ReactElement => {
  const history = useHistory()
  const dispatch = useDispatch()
  const simulator = useSimulator()
  const search = queryString.parse(history.location.search)
  const [cities, setCities] = useState<Option[]>([])
  const [city, setCity] = useState(form.city)
  const [uf, setUf] = useState(form.uf)
  const [isOpen, setIsOpen] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)
  const [tip, setTip] = useState(false)
  const [radioValue, setRadioValue] = useState('')

  const [openConfirmEmail, setOpenConfirmEmail] = useState(false)
  const [changeEmail, setChangeEmail] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const states = getStates()

  useEffect(() => {
    setCities(getStateCities(uf))
  }, [uf])

  const getLimits = async () => {
    try {
      const response = await mainApi.get('/globals/last')
      if (response.data?.data?.global) {
        dispatch(setImovelValues(response.data?.data?.global))
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setCities(getStateCities(form.uf.toUpperCase())) // eslint-disable-next-line
    setCity(form.city) // eslint-disable-next-line 
    getLimits() // eslint-disable-next-line 
  }, [])

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUf(e.target.value)
  }

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCity(e.target.value)
  }

  const templateAreas = useBreakpointValue({
    lg: `'type type . . .' 
    'name name . . .'
    'cpf cpf . . .'
    'birthDate birthDate . . .'
    'rg rg . . .'
    'motherName motherName . . .'
    'phone phone . . .'
    'email email . . .'
    'income income . . .'
    'value value . . .' 
    'uf city . . .' 
    'isNew isNew . . .' 
    'terms terms . . .' 
    '. . . . submit'`,
    md: `'type type . . ' 
    'name name . .'
    'cpf cpf . .'
    'birthDate birthDate . .'
    'rg rg . .'
    'motherName motherName . .'
    'phone phone . .'
    'email email . .'
    'income income . .'
    'value value . .' 
    'uf city  . .' 
    'isNew isNew . .' 
    'terms terms . .' 
    '. . submit submit'`,
    base: `'type' 
    'name'
    'cpf'
    'birthDate'
    'rg'
    'motherName'
    'phone'
    'email'
    'income'
    'value' 
    'uf' 
    'city'
    'isNew' 
    'terms'
    'submit'`
  })

  const sendForm = async () => {
    if (!simulator.entryId) return
    dispatch(setFormItem({ key: 'confirmEmail', value: confirmEmail }))
    setLoading(true)
    const indikyLinkId = search?.agx_hash || '612011e164de0a5bdc32a7cb'
    try {
      const response = await mainApi.post(`/simulations/${simulator.entryId}/sac`, {
        email: confirmEmail,
        confirmEmail,
        changeEmail,
        finish: true,
        indikyLinkId
      })
      if (response) {
        if (global.followUp === _followUp.platform) setStep('finish')
        if (global.followUp === _followUp.link) setStep('finishPageWithLink')
      }
    } catch (error) {
      if (!(error as any).response) {
        dispatch(setError({}))
        console.warn(error)

        Alert.fire({ type: "error", title: "Error", body: "Não foi possível se comunicar com o servidor! Tente novamente mais tarde!" })
      } else if ((error as any).response.status === 422) {
        const invalidValues = interate.invalid((error as any)?.response?.data?.errors)
        dispatch(setError(invalidValues))
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    (search?.hasSimulator !== "true" && search?.hasSimulator) && simulator.entryId && setOpenConfirmEmail(true)
  }, [search?.hasSimulator, simulator.entryId])

  return (
    <>
      <ModalTerms isOpen={openTerms} onClose={() => setOpenTerms(false)} title={<h1 className="about-entry__title-terms">TERMOS DE USO</h1>} termsText={<LGPDText />} />
      <ModalIncome isOpen={isOpen} onClose={() => { setIsOpen(false) }} />
      <ModalConfirmEmail
        open={openConfirmEmail}
        isLoading={loading}
        confirmEmail={confirmEmail}
        onClose={() => { setOpenConfirmEmail(false) }}
        onSend={sendForm}
        setConfirmEmail={(value: string) => { setConfirmEmail(value) }}
        setChangeEmail={(value: boolean) => { setChangeEmail(value) }}
      />
      <Grid
        padding="8"
        gridGap="2"
        gridTemplateAreas={templateAreas}
      >
        <GridItem gridArea="type">
          <Text fontWeight="500">Tipo de Imóvel:</Text>
          <Radio
            name="type"
            options={[
              { label: "Residencial", value: "residential" },
              { label: "Comercial", value: "commercial" },
            ]}
            defaultValue={form.type || "residential"}
            error={errors.type}
          />
        </GridItem>

        <GridItem gridArea="name">
          <Text fontWeight="500">Nome completo*:</Text>
          <TextInput name="name" defaultValue={form.name} error={errors.name} />
        </GridItem>

        <GridItem gridArea="cpf">
          <Text fontWeight="500">CPF do Comprador*:</Text>
          <TextInput id="cpf" name="cpf" mask="cpf" defaultValue={form.cpf} error={errors.cpf} />
        </GridItem>

        <GridItem gridArea="birthDate">
          <Text fontWeight="500">Data de Nascimento do Comprador*:</Text>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            name='birthDate'
            placeholderText="Ex: 01/01/1985"
            error={!!errors.birthDate}
            onKeyDown={(e) => {
              if (e.code === "Tab" || e.keyCode === 9) {
                const go = e.shiftKey ? '#cpf' : '#rg'
                e.preventDefault()
                const nextInput: HTMLInputElement | null = document.querySelector(go)
                const datePicker: HTMLDivElement | null = document.querySelector('.react-datepicker__tab-loop')
                if (datePicker) datePicker.style.display = 'none'
                nextInput?.focus()
              }
            }}
            onFocus={(e) => {
              const datePicker: HTMLDivElement | null = document.querySelector('.react-datepicker__tab-loop')
              if (datePicker) datePicker.style.display = 'initial'
            }}
          />
          {errors.birthDate && (
            <label className="birthDate-error">
              {errors.birthDate}
            </label>)
          }
        </GridItem>

        <GridItem gridArea="rg">
          <Text fontWeight="500">Selecione o tipo de documento (opcional):</Text>
          <Radio
            name="id"
            onChange={e => setRadioValue(e.target.value)}
            options={[
              { label: "RG", value: "rg" },
              { label: "RNE", value: "rne" }
            ]}
          />
          <TextInput
            name={radioValue === 'rg' ? 'rg' : 'rne'}
            mask={radioValue === 'rg' ? 'rg' : 'rne'}
            defaultValue={form.rg}
            error={errors.rg}
            placeholder={radioValue === '' ? '' : radioValue === 'rg' ? 'Digite seu RG (não precisa colocar pontos e traços)' : 'Digite seu RNE (não precisa colocar pontos e traços)'}
          />
        </GridItem>

        <GridItem gridArea="motherName">
          <Text fontWeight="500">Nome da mãe (opcional):</Text>
          <TextInput name="motherName" defaultValue={form.motherName} error={errors.motherName} />
        </GridItem>

        <GridItem gridArea="phone">
          <Text fontWeight="500">Telefone/Celular*:</Text>
          <TextInput name="phone" mask="phone" defaultValue={form.phone} error={errors.phone} />
        </GridItem>

        <GridItem gridArea="email">
          <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
            <Text flex={"1 1 100px"} fontWeight="500">E-mail*:</Text>
            <Text flex={"1 2 300px"} fontSize=".75rem" fontWeight="400">Atenção: Seu e-mail será utilizado posteriormente, por favor inserir um e-mail válido.</Text>
          </Box>

          <TextInput name="email" type='email' defaultValue={form.email} error={errors.email} />
        </GridItem>

        <GridItem gridArea="income">
          <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
            <Text fontWeight="500">Renda total*:</Text>
            <InfoOutlineIcon onClick={() => setIsOpen(true)} style={{ cursor: 'pointer', color: theme.colors.secondary[300] }} />
          </Box>
          <InputMoney
            name="income"
            defaultValue={form.income}
            error={errors.income}
            onFocus={() => { !tip && setIsOpen(true); setTip(true) }}
          />
        </GridItem>

        <GridItem gridArea="value">
          <>
            <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
              <Text fontWeight="500">Valor do Imóvel*:</Text>
              <Text fontSize=".75rem" fontWeight="400">{`Min: ${format.formatBRLMinAndMax(global.imovelValues.min)} - Máx: ${format.formatBRLMinAndMax(global.imovelValues.max)}`}</Text>
            </Box>
            <InputMoney
              name="value"
              defaultValue={form.value}
              error={errors.value}
            />
          </>
        </GridItem>

        <GridItem gridArea="uf">
          <Text fontWeight="500">Estado:</Text>
          <Select name="uf" options={states} onChange={handleStateChange} error={errors.uf} defaultValue={uf} />
        </GridItem>

        <GridItem gridArea="city">
          <Text fontWeight="500">Cidade:</Text>
          <Select name="city" minWidth="300px" options={cities} onChange={handleCityChange} error={errors.city} defaultValue={city} />
        </GridItem>

        <GridItem gridArea="isNew">
          <Text fontWeight="500">{layout.text.isNew}</Text>
          <Radio
            name="isNew"
            options={layout?.options.isNew ||
              [
                { label: "Novo", value: "true" },
                { label: "Usado", value: "false" },
              ]
            }
            defaultValue={(() => {
              if (layout?.form.isNew) return layout?.form.isNew
              return format.booleanToString(form.isNew) || "true"
            })()
            }
            error={errors.isNew}
          />
        </GridItem>

        <GridItem gridArea="terms">
          <Check
            name="terms"
            error={errors.terms}
          >
            <div>
              Eu li e concordo com os
              <span style={{ color: theme.colors.secondary[500] }} className="about-entry__checkbox-terms" onClick={() => setOpenTerms(true)}> termos de uso</span>
            </div>
          </Check>
        </GridItem>


        <Button colorScheme="secondary" variant="solid" type="submit" gridArea="submit">
          {(search?.hasSimulator === "true" || !search?.hasSimulator) ? 'Proximo' : 'Quero contratar'}
        </Button>
      </Grid>
    </>
  )
}

export default connector(StepOne)
