
import React, { useState, ReactNode, useEffect }  from 'react'
import { connect } from 'react-redux'

import ArrowLeft from 'assets/svg/ArrowLeft'
import ArrowRight from 'assets/svg/ArrowRight'
import Dot from 'assets/svg/Dot'

import step1 from 'assets/images/indikySteps/web/step1.png'
import step2 from 'assets/images/indikySteps/web/step2.png'
import step3 from 'assets/images/indikySteps/web/step3.png'
import step4 from 'assets/images/indikySteps/web/step4.png'

import stepMobile1 from 'assets/images/indikySteps/mobile/step1.png'
import stepMobile2 from 'assets/images/indikySteps/mobile/step2.png'
import stepMobile3 from 'assets/images/indikySteps/mobile/step3.png'
import stepMobile4 from 'assets/images/indikySteps/mobile/step4.png'
import { Button } from '@chakra-ui/react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import queryString from 'query-string'
import Cookies from 'universal-cookie'
import Swal from 'sweetalert2'
import variables from 'config/variables'

const mapState = () => {
  return {}
}

const mapDispatch = () => ({})

const connector = connect(mapState, mapDispatch)

interface IProps {
  setStep: Function
}
const FinishPage = ({setStep}: IProps) => {
  const [selectedSlide, setselectedSlide] = useState(0)
  const [hasPrevious, setHasPrevious] = useState(false)
  const [hasNext, setHasNext] = useState(true)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const search = queryString.parse(history.location.search)
  const cookies = new Cookies()
  
  const slides = [
    {number: '01', describe: <p>Você recebera um e-mail do indiky para dar andamento na sua proposta, clique no botão "<strong>CADASTRAR SENHA</strong>".</p>, image: step1, mobile: stepMobile1},
    {number: '02', describe: <p>Ao acessar o link defina uma senha para acessar a plataforma e acompanhar os status de sua proposta.</p>, image: step2, mobile: stepMobile2},
    {number: '03', describe: <p>Ao entrar na plataforma localize sua proposta e clique sobre o botão "<strong>Acessar proposta</strong>".</p>, image: step3, mobile: stepMobile3},
    {number: '04', describe: <p>Você deve fazer o envio de todos os documentos necessários, basta clicar sobre o nome do documento</p>, image: step4, mobile: stepMobile4}
  ]

  type ISlide = {
    number: string
    describe: string | ReactNode
    image: string,
    mobile: string
  }

  const changeSlide = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>,to: number) => {
    e.stopPropagation()
    e.preventDefault()
    const slideNumber = ((selectedSlide + to) % slides.length)
    if(slideNumber >= 0 && slideNumber < slides.length) {
      setselectedSlide(slideNumber)
    }
  }

  useEffect(() => {
    setHasPrevious(selectedSlide > 0)
    setHasNext(selectedSlide < slides.length - 1)
  }, [selectedSlide, slides.length])

  const handleNewProposal = async () => {
    const agxHash = search?.agx_hash
    setLoading(true)
    try {
      const response = await axios.post(`${variables.shortenUrl}/hashes/${agxHash}/generate`)
      const newAgxHash = response.data.data.hashId
      setLoading(false)
      cookies.set('cookiesProposalId', '')
      cookies.set('simulationId', '')
      window.location.assign(`/?agx_hash=${newAgxHash}`)
    } catch (err: any) {
      let errorMessage = ''
      console.log(err)

      if (typeof err === 'string') errorMessage = err
      if (err && typeof err === 'object' && typeof err.message === 'string') errorMessage = err.message
      if (err && typeof err === 'object' && typeof err.message === 'object') errorMessage = JSON.stringify(err.message)

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage
      })
      setLoading(false)
    }
  }

  return (
    <div className="Carousel__container">
      <div className="Carousel__header">
        <div className="Carousel__header Carousel__header--number">
         <span >{slides[selectedSlide].number} </span>
        </div>
        {slides[selectedSlide].describe}
      </div>
      <div className="Carousel__slide">
        { 
          hasPrevious && 
          <span className="Carousel__button Carousel__button--left" onClick={(e) => {changeSlide(e, -1)}}>
            <ArrowLeft fill="var(--secondary-color-600)" height="2.5rem" width="2.5rem"/>
          </span>
        }
        <img className="Carousel__image Carousel__image--web" src={slides[selectedSlide].image} alt="" />
        <img className="Carousel__image Carousel__image--mobile" src={slides[selectedSlide].mobile} alt="" />
        {
          hasNext &&
          <span 
            className="Carousel__button Carousel__button--right" 
            onClick={(e)=> changeSlide(e, 1)}
            data-tip data-for="nextStep"
            >
            <div className="Carousel__tooltipContainer">
              <p className="Carousel__tooltip">Siga o passo a passo!</p>
              <ArrowRight fill="var(--secondary-color-600)" height="2.5rem" width="2.5rem"/>
            </div>
          </span>
        }
      </div>
      <div className="Carousel__footer">
        <div className="Carousel__dots">
          {slides.map((slide:ISlide, idx:number) => (
            <span key={slide.number} onClick={() => setselectedSlide(idx)}>
              <Dot
                fill={selectedSlide === idx ? 'var(--secondary-color-600)' : 'var(--brand-color-700)'}
              />
            </span>
          ))}
        </div>
      </div>
      <div className="Carousel__footer-button">
        <Button 
          colorScheme="secondary" 
          disabled={loading} 
          isLoading={loading}
          variant="solid" 
          onClick={handleNewProposal} 
        >
          Criar nova proposta
        </Button>
      </div>
    </div>
  );
};


export default connector(FinishPage);