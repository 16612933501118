import React, { ReactElement, useEffect, useState } from "react"
import { Button, Grid, GridItem, Text, useBreakpointValue } from "@chakra-ui/react"
import { RootState } from "../../store/modules/rootReducer"
import { connect, ConnectedProps } from "react-redux"
import { Slider } from '../../components/FormInputs'
import format from '../../utils/format'
import mainApi from '../../services/mainApi'
import { layout } from '../../config/projectStyle'
import Alert from "../../components/Alert/Alert"
import interate from "../../utils/interate"
import { IError } from "../../store/modules/error/types"
import { Dispatch } from "redux"
import { setError } from "../../store/modules/error/actions"
import { goToLink } from "../../utils/linkTools"
import axios from 'axios'
import variables from "../../config/variables"

const mapState = (state: RootState) => {
  return {
    form: state.form,
    step: state.step.active,
    errors: state.error,
    line: state.line,
    simulator: state.simulator,
    product: state.product
  }
}

const mapDispatch = (dispatch: Dispatch) => ({
  setErro: (errors: IError) => dispatch(setError(errors)),
})

type PropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapState, mapDispatch)

const StepOne = ({ form, line, errors, simulator, product }: PropsFromRedux): ReactElement => {
  const [minValue, setMinValue] = useState(100000)
  const [maxValue, setMaxValue] = useState(3000000)
  const [financy, setFinancy] = useState(0)
  const [value, setValue] = useState(form.value)
  const [loading, setLoading] = useState<boolean>(false)

  const handleSliderChange = () => {

    const
      range = document.getElementById('range'),
      rangeV = document.getElementById('rangeV')

    const
      newValue = Number(((range as any).value - (range as any).min) * 100 / ((range as any).max - (range as any).min)),
      newPosition = 10 - (newValue * 0.2);

    (rangeV as any).innerHTML = `<span>${format.toReal((range as any).value)}</span>`;
    (rangeV as any).style.left = `calc(${newValue}% + (${newPosition}px))`
    const aux = (range as any).value.replace(',', '.')
    const auxValue = Number(aux).toFixed(2)
    setValue(auxValue as any)
  }  

  const openLink = (link: string) => {
    if(layout.fullPage) window.open(link)
    else goToLink(link)
  }

  const handleFinishClick = async () => {
    
    let simulation

    const entryId = await getEntry()
    
    if(entryId) simulation = await newSimulation(true, entryId)    

    if(line.internal) {

      const lineServer = variables.lineServer || layout.lineServer

      const sim = simulation?.data.data      
      window.localStorage.setItem('simulationId', sim._id)
      try {        
        const axiosResponse = await axios.post(`${lineServer}/proposals`, {
          clientId: line.clientId,
          productId: line.productId,
          simulationId: sim._id
        }, { headers: { Authorization: `Bearer ${line.token}` } })
        
        window.top.postMessage(axiosResponse.data.data, '*')
      } catch(error) {
        if (!error.response) {
          setError({})
          console.warn(error)
  
          Alert.fire({ type: "error", title: "Error", body: "Não foi possível se comunicar com o servidor! Tente novamente mais tarde!"})
        } else if (error.response.status === 422) {
          const invalidValues = interate.invalid(error?.response?.data?.errors)
          // console.log(invalidValues)
          setError(invalidValues)
        }
      } finally {
        setLoading(false)          
      }      

    } else {

      let responseLink = simulation?.data?.data?.finishUrl
      openLink(responseLink)
    }    

  }
  
  const newSimulation = async (finish: boolean, entryId: string) => {         
    try {
      const response = await mainApi.post(`/simulations/${entryId}/sac`, { 
        finish,
        product: 'precatorio'
      })
      setError({})      
      if (finish) return response
    } catch (error) {
      if (!error.response) {
        setError({})
        console.warn(error)

        Alert.fire({ type: "error", title: "Error", body: "Não foi possível se comunicar com o servidor! Tente novamente mais tarde!" })
      } else if (error.response.status === 422) {
        const invalidValues = interate.invalid(error?.response?.data?.errors)
        setError(invalidValues)
      }
    }    
  }

  const getEntry = async () => {
    let newForm = { value }
    
    let productBody = product.slug ? { product: product.slug } : {}
    try {
      const response = await mainApi.post('/entries', { step: 'initial', data: newForm, ...productBody })
      const data: any = response.data.data      
      
      // console.log(data)

      if (data?.entry?._id) return data.entry._id         
      setError({})      
    } catch (error) {
      if (!error.response) {
        setError({})        
        Alert.fire({ type: "error", title: "Error", body: "Não foi possível se comunicar com o servidor! Tente novamente mais tarde!"})
      } else if (error.response.status === 422) {
        const invalidValues = interate.invalid(error?.response?.data?.errors)          
        setError(invalidValues)
      }
    }    
  }

  const getGlobal = async () => {

    try {
      const response = await mainApi.get('/globals/pricing?product='+product.sub+'&personType='+form.personType)
      const global = response.data.data.pricing.find((value: any) => value.modality === 'SAC')

      setMinValue(global.minValue.toFixed(2) || 100000.00)
      setValue(global.minValue.toFixed(2) || 100000.00)
      setMaxValue(global.maxValue.toFixed(2) || 3000000.00)
      setFinancy(global.financingPerc || 0.5)
    } catch (exception) {
      console.warn(exception)
    }
  }

  console.log(value)

  useEffect(() => {
    if(form.personType) getGlobal()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.personType])

  const templateAreas = useBreakpointValue({
    lg: `'min     .   value   value   value .   max' 
         '.       .   financy financy .     .   .  '
         '.       .   submit  submit  .     .   .'`,
    md: `'min     .   value   value   .     max' 
         '.       .   financy financy .     .'
         '.       .   submit  submit  .     .'` ,
    base: `
    'min'
    'value'
    'financy'
    'max'
    'submit'`
  })

  return (
    <Grid
      padding="8"
      gridGap="2"
      gridTemplateAreas={templateAreas}
    >

      <GridItem gridArea="min" alignSelf="end" justifySelf="center" paddingBottom="15px" >
        <Text fontWeight="100">Mínimo: {format.toReal(minValue)}</Text>
      </GridItem>

      <GridItem gridArea="value">        
        <Text fontWeight="500">{layout.text.slider || 'Valor do Imóvel'}*:</Text>

        <Slider
          name="value"
          defaultValue={value}
          min={minValue}
          max={maxValue}
          onChange={handleSliderChange}
        />        

        {/* 
        <Currency
          name="value"
          leftAddon="R$"
          defaultValue={value}
          onChange={(event: ChangeEvent<any>) => {            
            if(Number(event.target.value) <= Number(maxValue)) setValue(event.target.value)
            else setValue(maxValue)
          }}          
          max={maxValue}
          error={errors.value}
        /> 
        */}

      </GridItem>

      <GridItem gridArea="financy" display="flex" flexDirection="column" alignItems="center" justifyItems="center">
        <Text className="initial-value" color="primary.400">{format.toReal(financy * value)}</Text>
        <Text fontWeight="300">Valor disponível</Text>
      </GridItem>

      <GridItem gridArea="max" alignSelf="end" justifySelf="center" paddingBottom="15px">
        <Text fontWeight="100">Máximo: {format.toReal(maxValue)}</Text>
      </GridItem>
       
      {(layout.fullPage && layout.oneStep) ? (
        <Button isLoading={loading} colorScheme="secondary" variant="solid" onClick={handleFinishClick} gridArea="submit">
          Gerar negócio
        </Button>          
      ) : (
        <Button colorScheme="secondary" variant="solid" type="submit" gridArea="submit">
          Continuar
        </Button>
      )}     
    </Grid>
  )
}

export default connector(StepOne)
