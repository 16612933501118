import React, { useEffect } from 'react';
import { Dispatch } from "redux"
import { connect, ConnectedProps } from 'react-redux'
import queryString from 'query-string'

import Routes from './routes'

import { ILine } from './store/modules/line/types';
import { setLine } from './store/modules/line/actions'
import { RootState } from './store/modules/rootReducer';
import { setFollowUp } from 'store/modules/global/actions';
import {_followUp } from 'store/modules/global/types';
import mainApi from 'services/mainApi';

const mapState = (state: RootState) => ({ line: state.line })

const mapDispatch = (dispatch: Dispatch) => ({ 
  setLine: (line: ILine) => dispatch(setLine(line)),
  setFollowUp: (followUp: _followUp) => dispatch(setFollowUp(followUp))
})

type PropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapState, mapDispatch)

function App({ setLine, setFollowUp }: PropsFromRedux) {

  useEffect(() => {
    const path = window.location.pathname
    const internal = /internal/.test(path)

    const createObj: ILine = {
      internal,
      clientId: '',
      productId: '',
      token: ''
    } 

    const params = queryString.parse(window.location.search)
    if(params) {
      createObj.clientId = params.clientId as string
      createObj.productId = params.productId as string
      createObj.token = params.token as string
    }

    setLine(createObj)
  // eslint-disable-next-line react-hooks/exhaustive-deps

  async function getFollowUp () {
    try {
      const response = await mainApi.get('corbans/followUp')
      const { clientFollowUp } = response?.data?.data || {}
      setFollowUp(clientFollowUp)
    } catch (error) {
      console.log(error)
    }
  }

  getFollowUp()
  }, [])
  
  return (
    <Routes />
  );
}

export default connector(App)
