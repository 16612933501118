import { agx, rodobens, jive } from './projects'
import orioncred from './projects/orioncred'
// import variables from './variables'

const layout = (function(){
  let hostname = window.location.hostname.split('.')

  if(hostname.length === 2 || window.location.hostname === 'seuimovel.agxsoftware.com') return agx

  if (window.location.hostname === 'financiamento.orioncredmultibancos.com.br') return orioncred

  let [ , slug, ] = hostname

  if(slug === 'rodobens') return rodobens

  if(slug === 'jive') return jive

  // if(['development', 'local'].includes(variables.env)) return rodobens

  return agx
})()

export { layout }