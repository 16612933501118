import variables from "../variables"

const jive = {
  colors: {
    brand: undefined,
    primary: {
      10: '#ffffff',
      50: '#f7f7f7',
      100: '#06472a',
      200: '#3ced9a',
      300: '#29d382',
      400: '#1ea465',
      500: '#137647',
      600: '#62f1b1',
      700: '#1ea465',
      800: '#137647',
      900: '#06472a',
    },
    secondary: {
      50: '#f7fdde',
      100: '#e8f7b6',
      200: '#daf18b',
      300: '#cceb5f',
      400: '#bee533',
      500: '#a4cc1a',
      600: '#809e11',
      700: '#5a7108',
      800: '#364402',
      900: '#101800',
    }
  },
  fullPage: true,
  form: {
    isNew: "false"
  } as AutoContratacao.IFormParams,
  options: {
    isNew: [
      { label: "Usado", value: "false" },
    ]
  } as AutoContratacao.IFormParams,
  text: {
    isNew: 'O seu imóvel é?',
    financy: 'Valor de crédito disponível',
    slider: 'Valor do Precatório'
  },
  initialStep: true,
  oneStep: true,
  slug: 'jive',
  showParcels: true,
  lineServer: variables.jiveServer || 'https://jive-production-server.herokuapp.com'
}

export default jive