
import React, { useEffect, useMemo, useState } from 'react'
import mainApi from 'services/mainApi'
import queryString from 'query-string'
import handleError from '../../utils/error'

import { ReactComponent as AddFilesSvg } from '../../assets/svg/add-files.svg'
import { ReactComponent as ShareSvg } from '../../assets/svg/share.svg'
import { ReactComponent as ErrorSvg } from '../../assets/svg/error.svg'
import { ReactComponent as SuccessSvg } from '../../assets/svg/success.svg'
import { ReactComponent as ProcessSvg } from '../../assets/svg/process.svg'
import { Skeleton } from '@chakra-ui/react'

import { connect, ConnectedProps } from 'react-redux'
import { RootState } from 'store/modules/rootReducer'
import { Tooltip } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { Dispatch } from "redux"
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import Cookies from 'universal-cookie'
import variables from 'config/variables'

const mapState = (state: RootState) => {
  return {
    form: state.form,
    simulator: state.simulator,
    errors: state.error,
    line: state.line,
    product: state.product,
    global: state.global
  }
}
const mapDispatch = (dispatch: Dispatch) => ({})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>
interface IProps extends PropsFromRedux {
  setStep: any
}

function FinishPageWithLinkWrapper ({ children, error, loading }: { children: React.ReactNode, error: boolean, loading: boolean }) {
  if (error) {
    return (
      <>
        <h1 className='finish-with-link__title'>OPS!</h1>
        <p className='finish-with-link__paragraph finish-with-link__paragraph--error'>Infelizmente ocorreu um erro no envio do seu link. Sentimos muito pelo inconveniente, por favor, tente novamente mais tarde.</p>
        <ErrorSvg className='finish-with-link__main-icon finish-with-link__main-icon--error' />
      </>
    )
  }

  if (loading) {
    return (
      <>
        <h1 className='finish-with-link__title'>Buscando proposta...</h1>
        <p className='finish-with-link__paragraph finish-with-link__paragraph--error'>Não saia dai! Estamos buscando a sua proposta. Por favor, aguarde.</p>
        <ProcessSvg className='finish-with-link__main-icon finish-with-link__main-icon--load' />
        <Skeleton className='finish-with-link__skeleton'/>
      </>
    )
  }

  return <>{children}</>
}

const FinishPageWithLink = ({ simulator, product, form, errors, line, global }: IProps) => {
  const [documentLink, setDocumentLink] = useState('')
  const [followUpLink, setFollowUpLink] = useState('')
  const [errorLink, setErrorLink] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [fetchFollowUpLink, setFetchFollowUpLink] = useState(false)

  const history = useHistory()
  const search = queryString.parse(history.location.search)
  const cookies = new Cookies()
  const proposalId = cookies.get('proposalId')

  const link = useMemo(() => {
     return followUpLink ? followUpLink : documentLink
  }, [followUpLink, documentLink]) 

  const mainIcon = followUpLink ? <SuccessSvg className='finish-with-link__main-icon' /> : <AddFilesSvg className='finish-with-link__main-icon'/>
  const title = followUpLink ? 'Documentos enviados com sucesso' : 'Envio de documentos'
  const paragraph = followUpLink ? 'Seus documentos foram enviados com sucesso, segue abaixo o link de acompanhamento da sua proposta. O mesmo será enviado em seu e-mail.' : 'Para dar continuidade em sua proposta preencha os documentos no link abaixo. Feito isso você receberá um link de acompanhamento em seu e-mail.'

  async function findDocumentLink () {
    setLoading(true)
    try {
      const response = await mainApi.get(`simulations/proposals/${proposalId}/documentsLink`)
      if (!response) throw new Error('Infelizmente não foi possivel receber o seu link.')

      const { documentsLink } = response?.data?.data || {}
      if (documentsLink) setDocumentLink(documentsLink)
      setFetchFollowUpLink(prev => !prev)
      setLoading(false)
    } catch (error) {
      if (error?.response?.status === 404) {
        setTimeout(findDocumentLink, 3000)
      } else {
        setLoading(false)
        setErrorLink(true)
      }
    }
  }

  async function findFollowUpLink () {
    try {
      const response = await mainApi.get(`simulations/proposals/${proposalId}/proposalFollowUpLink`)
      const { proposalFollowUpLink } = response?.data?.data || {}
      setFollowUpLink(proposalFollowUpLink)
      
    } catch (error) {
      if (error?.response?.status === 404 || error?.response?.status === 400) {
        setTimeout(() => setFetchFollowUpLink(prev => !prev), 10000)
      } else {
        setErrorLink(true)
      }
    }
  }

  const handleNewProposal = async () => {
    const agxHash = search?.agx_hash
    setLoadingButton(true)
    try {
      const response = await axios.post(`${variables.shortenUrl}/hashes/${agxHash}/generate`)
      const newAgxHash = response.data.data.hashId
      
      cookies.set('cookiesProposalId', '')
      cookies.set('proposalId', '')

      window.location.assign(`/?agx_hash=${newAgxHash}`)
    } catch (err) {
      handleError(err)
    } finally { setLoadingButton(false) }
  }

  useEffect(() => {
    if (!proposalId) return
    findDocumentLink()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalId])

  useEffect(() => {
    if (!proposalId || !documentLink) return
    findFollowUpLink()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFollowUpLink, proposalId])

  return (
    <FinishPageWithLinkWrapper error={errorLink} loading={loading}>
      <div className='finish-with-link'>
        <h1 className='finish-with-link__title'>{title}</h1>

        <div className='finish-with-link__body-container'>
          {mainIcon}

          <p className='finish-with-link__paragraph'>{paragraph}</p>
          
          <div className='finish-with-link__link-container'>
            <span className='finish-with-link__link'>
              <a href={link} target='_blank' rel="noreferrer">{link}</a>
            </span>
          
            <Tooltip hasArrow label='Clique para acessar' placement='top'>
              <a
                href={link}
                target='_blank' rel="noreferrer"
                className='finish-with-link__share-icon-container'
              >
                <ShareSvg className='finish-with-link__share-icon' />
              </a>
            </Tooltip>
          </div>
        </div>

        {followUpLink && <div className="finish-with-link__footer-button">
          <Button 
            colorScheme="secondary" 
            disabled={loadingButton} 
            isLoading={loadingButton}
            variant="solid" 
            onClick={handleNewProposal} 
          >
            Criar nova proposta
          </Button>
        </div>}
      </div>
    </FinishPageWithLinkWrapper>
  )
}

export default connector(FinishPageWithLink)
