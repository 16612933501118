import {GlobalTypes} from './types'

const _initialState = {
  isLoading: false,
  agxHash: '',
  imovelValues:{
    max: 0,
    min: 0
  }
}

const resources = (state = _initialState, action: GlobalTypes ) => {
  switch (action.type) {
    case 'SET_LOADING': {
      const { isLoading } = action.payload
      return {
        ...state,
        isLoading
      }
    }
    case 'SET_AGXHASH': {
      const { agxHash } = action.payload
      return {
        ...state,
        agxHash
      }
    }
    case 'SET_IMOVEL_VALUES':{
      return {
        ...state,
        imovelValues: { 
          ...action.payload,
          max: action.payload.maxValue,
          min: action.payload.minValue
        }
      }
    }
    case 'SET_FOLLOW_UP': {
      const { followUp } = action.payload
      return {
        ...state,
        followUp
      }
    }

    default:
      return state
  }
}

export default resources
