import Swal from 'sweetalert2'

function handleError (err: string | { message: string | object}) {
  let errorMessage = ''
  console.log(err)

  if (typeof err === 'string') errorMessage = err
  if (err && typeof err === 'object' && typeof err.message === 'string') errorMessage = err.message
  if (err && typeof err === 'object' && typeof err.message === 'object') errorMessage = JSON.stringify(err.message)

  Swal.fire({
    icon: 'error',
    title: 'Error',
    text: errorMessage
  })
}

export default handleError
