import { Image, Heading, Box, Flex } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import logo from "../../assets/images/logo.png"
import orioncredLogo from "../../assets/images/orioncred-logo.png"

const SimulationPageHeader = () => {
  const [logoSrc, setLogoSrc] = useState('')
  const hostname = window.location.hostname
  const canHeight = hostname === 'financiamento.orioncredmultibancos.com.br'

  useEffect(() => {
  const domainLogoMap: { [key: string]: string } = {
    'financiamento.orioncredmultibancos.com.br': orioncredLogo
  }

  function getLogoSrcByDomain(domain: string): string {
    return domainLogoMap[domain] || logo;
  }

  const logoSrc = getLogoSrcByDomain(window.location.hostname);
  setLogoSrc(logoSrc);
}, [])

  return (
    <Flex h="100%">
      <Flex w="55%" alignItems="baseline">
        <Heading fontSize={["2xl", "4xl", "5xl"]} lineHeight="1em" color="white" marginY="10">
          Simule seu financiamento
      </Heading>
      </Flex>

      <Box w="45%" marginY="10">
        <Image
          src={logoSrc}
          width={canHeight ? '100%' : undefined}
          height={canHeight ? undefined : '100%'}
        />
      </Box>
    </Flex>
  )
}

export default SimulationPageHeader
