import { extendTheme } from "@chakra-ui/react"
import { layout } from '../../config/projectStyle'

//console.log(layout)
const colors = layout?.colors

const fonts = {
  body: "Heebo , sans-serif, system-ui",
  heading: "Heebo, Georgia, sans-serif",
  mono: "Menlo, monospace",
}

const theme = extendTheme({ colors, fonts })
export default theme

// Set default variables
const root = document.documentElement

root.style.setProperty('--primary-color', colors.primary[900])
root.style.setProperty('--primary-color-800', colors.primary[800])
root.style.setProperty('--primary-color-700', colors.primary[700])
root.style.setProperty('--primary-color-600', colors.primary[600])
root.style.setProperty('--primary-color-500', colors.primary[500])
root.style.setProperty('--primary-color-400', colors.primary[400])
root.style.setProperty('--primary-color-300', colors.primary[300])
root.style.setProperty('--primary-color-200', colors.primary[200])
root.style.setProperty('--primary-color-100', colors.primary[100])
root.style.setProperty('--primary-color-50', colors.primary[50])
root.style.setProperty('--primary-color-10', colors.primary[10])

root.style.setProperty('--secondary-color-900', colors.secondary[900])
root.style.setProperty('--secondary-color-800', colors.secondary[800])
root.style.setProperty('--secondary-color-700', colors.secondary[700])
root.style.setProperty('--secondary-color-600', colors.secondary[600])
root.style.setProperty('--secondary-color-500', colors.secondary[500])
root.style.setProperty('--secondary-color-400', colors.secondary[400])
root.style.setProperty('--secondary-color-300', colors.secondary[300])
root.style.setProperty('--secondary-color-200', colors.secondary[200])
root.style.setProperty('--secondary-color-100', colors.secondary[100])
root.style.setProperty('--secondary-color-50', colors.secondary[50])

if (colors?.brand) {
  root.style.setProperty('--brand-color-900', colors.brand[900])
  root.style.setProperty('--brand-color-900', colors.brand[800])
  root.style.setProperty('--brand-color-900', colors.brand[700])
}