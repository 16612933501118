import {IGlobalsImovel, Loading, SET_LOADING, _followUp} from './types'

export function setLoading (loading: Loading) {
  return {
    type: SET_LOADING,
    payload: loading
  }
}

export const setAgxHash = (agxHash: string) => ({
  type: 'SET_AGXHASH',
  payload: { agxHash }
})

export const setImovelValues = (global:IGlobalsImovel) => ({
  type: 'SET_IMOVEL_VALUES',
  payload: global
})

export const setFollowUp = (followUp: _followUp) => ({
  type: 'SET_FOLLOW_UP',
  payload: { followUp }
})

