export const SET_LOADING = 'SET_LOADING'

export interface IGlobal {
  isLoading: boolean
  agxHash: string
  imovelValues: IimovelValues
  followUp: _followUp
}

interface IimovelValues extends IGlobalsImovel {
  max: number,
  min: number
}

export interface IGlobalsImovel {
  aaPrice?: any[]
  anualTaxPRICE?: number
  anualTaxSAC?: number
  commercialMin?: number
  createdAt?: string
  entryPerc?:number
  financingMax?: number
  financingPerc?: number
  juristicalOptions?: any[]
  maxValue?: number
  minValue?: number
  partnerReturn?:any[]
  residentialMin?: number
  tacOptions?: any[]
  updatedAt?: string
}
export interface Loading {
  isLoading: boolean
}
export interface agxHash {
  agxHash: string
}

export enum _followUp {
  platform = 'platform',
  link = 'link'
}
interface setLoading {
  type: typeof SET_LOADING
  payload: Loading
}

interface ISET_AGXHASH {
  type: 'SET_AGXHASH'
  payload: agxHash
}
interface ISET_IMOVEL_VALUES{
  type: 'SET_IMOVEL_VALUES'
  payload: IGlobalsImovel
}


interface ISET_FOLLOW_UP{
  type: 'SET_FOLLOW_UP'
  payload: { followUp: _followUp}
}

export type  GlobalTypes = setLoading | ISET_AGXHASH | ISET_IMOVEL_VALUES | ISET_FOLLOW_UP

