const orioncred = {
  colors: {
    brand: {
      900: "#90A728", // dark
      800: "#C4E538", // brand color
      700: "#DAEB8D", // light
    },
    secondary: {
      50: '#CBD2FF',
      100: '#8194FF',
      200: '#5A71F3',
      300: '#475DDC',
      400: '#384DBF',  // secondary color
      500: '#2238B2', 
      600: '#1C2C8B',
      700: '#13227A', 
      800: '#040F50', 
      900: '#00093A', 
    },
    primary: {
      10: '#f8f8f9',
      50: '#e8e8e9',
      100: '#dadad9',
      200: '#c0c0c0',
      300: '#a7a7a7',
      400: '#8e8e8e',
      500: '#747474',
      600: '#5a5a5a',
      700: '#414141',
      800: '#272726',
      900: '#79d2eb', // predominant color
    },
  },
  fullPage: false,
  form: {} as AutoContratacao.IFormParams,
  options: {} as AutoContratacao.IFormParams,
  text: {
    isNew: 'O Imóvel que deseja comprar é?',
    financy: 'Valor do financiamento',
    slider: undefined
  },
  initialStep: false,
  showParcels: false,
  oneStep: false,
  lineServer: undefined
}

export default orioncred